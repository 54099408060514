import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import Headline from "../components/typography/headline";
import Paragraph from "../components/typography/paragraph";

const FAQPage = () => {
  const data = useStaticQuery(graphql`
    query {
      faq: file(relativePath: { eq: "faq.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="FAQ" />
      <SimplePage
        title="FAQ"
        description="Frequently Asked Questions"
        fluid={data.faq.childImageSharp.fluid}
      >
        <Headline>Free returns?</Headline>
        <Paragraph>
          100%. We stand by our product and will offer full refunds to our
          customers within 30 days of receipt of their canvas. Please contact
          support@imagineart.ai or send us a message on our facebook page.
        </Paragraph>
        <Headline>
          My canvas looks slightly different from the online image?
        </Headline>
        <Paragraph>
          In the process of converting a low-resolution image into a
          high-resolution image, Bartimaeus (our AI) continues to stylize your
          selected image. As a result, your final on-canvas print will often
          appear <i>slightly</i> different from the image you view online. See
          Physical Prints for examples.
        </Paragraph>
        <Headline>How long will it take for my canvas to arrive?</Headline>
        <Paragraph>
          We will send two automated emails to the email used in checkout:
          <br />
          1. Once we finish processing your payment.
          <br />
          2. A second email once your order is shipped.
          <br />
          The standard turn-around time from when you place the order to when it
          arrives at your destination is under 2 weeks.
        </Paragraph>
        <Headline>Do you ship outside the United States?</Headline>
        <Paragraph>Not yet.</Paragraph>
      </SimplePage>
    </Layout>
  );
};

export default FAQPage;
