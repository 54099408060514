import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  text: {
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "36px",
    lineHeight: "125%",
    fontWeight: "600",
    [theme.breakpoints.down(991)]: {
      fontSize: "24px",
    },
  },
}));

const Headline = ({ children, className }) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={clsx(classes.text, className)}>
      {children}
    </Typography>
  );
};

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Headline;
